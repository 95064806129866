import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UseImage, InputField, UploadDcumentsControl, CustomLoadingOverlay, Alert } from "../../components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom'
import iconMeet from "../../assets/iconoMeet.png";
import { UploadDcumentsControlMultiple } from "../../components/DocumentUpload/UploadDcumentsControlMultiple";
import { useDispatch, useSelector } from "react-redux";
import { flujoMasivoActions } from "../../store/actions";
import TableDataDocumentsFilters from "./TableDataDocuments";
import plantillaExcel from "../../assets/Plantilla_Metadatos.xlsx";
import img from "../../assets/DownloadExcel.png";
import PlantillaMetadatos_MXEXP from "../../assets/PlantillaMetadatos_MXEXP.xlsx";
import PlantillaMetadatos_MXINP from "../../assets/PlantillaMetadatos_MXINP.xlsx";
import PlantillaMetadatos_MXSTD from "../../assets/PlantillaMetadatos_MXSTD.xlsx";
import PlantillaMetadatos_MXWSM from "../../assets/PlantillaMetadatos_MXWSM.xlsx";
import PlantillaMetadatos_CLMSV from "../../assets/PlantillaMetadatos_CLMSV.xlsx";
import PlantillaMetadatos_BRASTD from "../../assets/PlantillaMetadatos_BRASTD.xlsx";
import PlantillaMetadatos_SPEN from "../../assets/PlantillaMetadatos_SPEN.xlsx";
import { useTranslation } from 'react-i18next';

export default function BulkMetadataUpload(props) {
  const uploadBulkMetadataState = useSelector((state) => state.UploadBulkFileMetadata);
  const bulkMetadataDetailsState = useSelector((state) => state.GetBulkMetadataDetails);

  const formMethods = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation()

  const [documentsUpload, setDocumentsUpload] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [loadView, setLoadView] = useState(null);
  const [dataDocument, setDataDocument] = useState(null);
  const [exteDocument, setExteDocument] = useState(null);
  const [activeCargaMasiva, setActiveCargaMasiva] = useState(false)

  const { t } =  useTranslation();

  useEffect(() => {
    resetData(true)
  }, []);

  useEffect(() => {
    setLoadView(uploadBulkMetadataState.loading)
    console.log("uploadBulkMetadataState?.error-----------++++++: ", uploadBulkMetadataState?.error)
    if (uploadBulkMetadataState?.error != undefined && uploadBulkMetadataState?.error != null && activeCargaMasiva) {
      setActiveCargaMasiva(false)
      setBase64File(null);
      Alert({
        icon: 0,
        title: uploadBulkMetadataState?.error
      })
    }
    else if (!!uploadBulkMetadataState.items) {
      console.log("documentsUpload 0", uploadBulkMetadataState);
      setDocumentsUpload(uploadBulkMetadataState.items);
    }
    else if (uploadBulkMetadataState.items == undefined) {
      console.log("duploadBulkMetadataState.items undefined", uploadBulkMetadataState.items);
      setActiveCargaMasiva(false)
      setBase64File(null);
    }
  }, [uploadBulkMetadataState]);

  useEffect(() => {
    setLoadView(bulkMetadataDetailsState.loading)
    console.log("uploadBulkMetadataState?.error-----------++++++: ", bulkMetadataDetailsState?.error)
    if (bulkMetadataDetailsState?.error != undefined && bulkMetadataDetailsState?.error != null) {
      setActiveCargaMasiva(false)
      setBase64File(null);
      Alert({
        icon: 0,
        title: bulkMetadataDetailsState?.error
      })
    }
    else if (!!bulkMetadataDetailsState.items) {
      console.log("documentsUpload 0", bulkMetadataDetailsState);
      setDocumentsUpload(bulkMetadataDetailsState.items);
    }
    else if (bulkMetadataDetailsState.items == undefined) {
      console.log("duploadBulkMetadataState.items undefined", bulkMetadataDetailsState.items);
      setActiveCargaMasiva(false)
      setBase64File(null);
    }
  }, [bulkMetadataDetailsState]);

  const resetData = (isInit) => {
    dispatch(flujoMasivoActions.UploadBulkFileMetadata(null, true));
    dispatch(flujoMasivoActions.GetBulkMetadataDetails(null, true));
    setDocumentsUpload([]);
    setBase64File(null);
    setDataDocument(null);
    setExteDocument(null);
    if (!isInit) {
      navigation("/inbox/BulkMetadata");
    }
  };

  const reloadDataDocument = () => {
    console.log("-----reloadDataDocument-----+++++..documentsUpload....++---------", documentsUpload)
    dispatch(
      flujoMasivoActions.GetBulkMetadataDetails({
        BatchUploadMetadataId: documentsUpload[0]?.BatchUploadMetadata_Id,
      })
    );
    dispatch(flujoMasivoActions.UploadBulkFileMetadata(null, true));
  };

  const handleFileInputChange = (fileContent, fileBase64, extensionFile) => {
    console.log("*************fileContent*********-----: ", fileContent);
    // console.log("*************FileContent******fileBase64***-----: ",      fileBase64    );
    // console.log(      "*************FileContent******extensionFile***-----: ",      extensionFile    );
    setBase64File(fileBase64);
    setDataDocument(fileContent);
    setExteDocument(extensionFile);
  };

  useEffect(() => {
    if (base64File !== null && base64File?.length > 1) {
      // console.log("dataCarga", dataDocument);
      console.log("...-----------location WorkFlowId .......----------: ", location.state?.WorkFlowId, " - ", location)
      console.log(".....Request Carga Masiva......:", {
        FileContentB64: base64File,
        Extension: exteDocument,
        FileName: dataDocument.name,
        WorkflowId: location.state?.WorkFlowId
      })
      setLoadView(true);
      dispatch(
        flujoMasivoActions.UploadBulkFileMetadata({
          FileContentB64: base64File,
          Extension: exteDocument,
          FileName: dataDocument.name,
          WorkflowId: location.state?.WorkFlowId
        })
      );
      setActiveCargaMasiva(true)
    }
  }, [base64File]);

  console.log("...-----------location WorkFlowId .......----------: ", location.state?.WorkFlowId, " - ", location)

  return (
    <> {loadView ?
      <CustomLoadingOverlay
        active={loadView}
        text={t("general_loading_message")}
      /> : null}
      {documentsUpload?.length > 0 ? (
        <>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12} xs={12}>
              <TableDataDocumentsFilters
                data={documentsUpload}
                CleanData={() => resetData()}
                ReloadData={() => reloadDataDocument()}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item lg={9} xs={12}>
            <Box textAlign={"justify"} display="flex" alignItems={"center"}>
              <Typography variant="subtitle">
                {t("bulkdata_upload_your_metadada")}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title={t("geid_download_template")} placement="top">
                {console.log(".....Tenent Download...Temp...:", location.state?.WorkFlowId, location.state)}
                <a
                  href={location.state?.WorkFlowId == 2 ?
                    PlantillaMetadatos_MXSTD : location.state?.WorkFlowId == 7 ?
                      PlantillaMetadatos_MXEXP : location.state?.WorkFlowId == 9 ?
                        PlantillaMetadatos_MXWSM : location.state?.WorkFlowId == 11 ?
                          PlantillaMetadatos_MXINP : location.state?.WorkFlowId == 3 ?
                            PlantillaMetadatos_CLMSV : location.state?.WorkFlowId == 12 && location.state?.WorkFlowName == "Contratación PROVIDA SPEN" ?
                              PlantillaMetadatos_SPEN : location.state?.WorkFlowId == 12 ?
                                PlantillaMetadatos_BRASTD : plantillaExcel
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  download="Plantilla Metadatos.xlsx"
                >
                  <IconButton xs={{ width: 60 }}>
                    <UseImage src={img} type="img" />
                  </IconButton>
                </a>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item lg={10} xs={12}>
            <FormProvider {...formMethods}>
              {base64File == null ?
                <UploadDcumentsControl
                  Excel={true}
                  Title={t("general_upload_your_file_xlsx")}
                  onChange={(fileContent, fileBase64, extensionFile) =>
                    handleFileInputChange(fileContent, fileBase64, extensionFile)
                  }
                /> : null}
            </FormProvider>
          </Grid>

          <Grid lg={5} xs={12}>
            <Box display={"flex"} justifyContent="center">
              <Button
                style={{
                  height: 38,
                  width: "100%",
                  margin: "1em",
                  borderRadius: "25px",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                variant="outlined"
                color="primary"
                onClick={() => navigation("/inbox/BulkMetadata")}
              >
                {t("general_cancel")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
